export function anim10() {
    var tl = gsap.timeline({ repeat: -1, repeatDelay: 2 });


    var tl2 = gsap.timeline({ repeat: -1, repeatDelay: 0 });



    tl2.to(".anim10-arrow-line", {
        duration: 2,
        strokeDashoffset: -100,
        ease: "none"
    });

    tl.to(".anim10-line line", {
        duration: 0.5,
        // y:100, 
        opacity: 0,
        scale: 2,
        immediateRender: false,
        transformOrigin: "50% 50%",
        ease: Power4.easeOut,
        stagger: .1
    });
    tl.fromTo(".anim10-line line", { transformOrigin: "50% 50%", opacity: 0, scale: 1, y: -100 }, {
        duration: 0.5,
        y: 0,
        opacity: 1,
        scale: 1,
        immediateRender: false,
        transformOrigin: "50% 50%",
        ease: Power3.easeOut,
        stagger: .1
    });

    return [tl, tl2];
}
