export function anim7() {

    var tl = gsap.timeline({ repeat: -1, repeatDelay: 0 });


    tl.to(".anim7-gear", {
        duration: 4,
        rotate: 360,
        ease: "none",
        transformOrigin: "50% 50%"
    });
    return tl;
}
