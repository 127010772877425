export function anim5() {

    var tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });
    var tl2 = gsap.timeline({ repeat: -1, repeatDelay: 0 });
    

    tl.fromTo(".anim5-btn", {
        
        duration: 0.5,
        scale:1,
        ease: Quint.easeIn,
        top:'50%',
        y:'-50%',
        transformOrigin:'50% 50%',
    },{
        duration: 0.5,
        scale:1.1,
        ease: Quint.easeIn,
        top:'50%',
        y:'-50%',
        transformOrigin:'50% 50%',
    });
    tl.to(".anim5-btn", {
        duration: 0.5,
        scale:1,
        ease: Quint.easeOut,
        top:'50%',
        y:'-50%',
        transformOrigin:'50% 50%',
    });

    tl2.to(".anim5-arrow-line", {
        duration: 2,
        strokeDashoffset: -100,
        ease: "none"
    });
  
    return [tl,tl2];



}
