export function anim4() {

    var tl = gsap.timeline({ repeat: -1, repeatDelay: 2 });
    var tl2 = gsap.timeline({ repeat: -1, repeatDelay: 0 });



    tl2.to(".anim4-arrow-line", {
        duration: 2,
        strokeDashoffset: -100,
        ease: "none"
    });
    
    
    tl.set(".anim4-invoice:nth-child(2)", { y: '-50%', top:'50%', scale:1 });
    tl.fromTo(".anim4-invoice:nth-child(2)", { y: '-50%', top:'50%', scale:1 }, {
        duration: 2,
        scale: 0.4,
        y: '-50%', 
        top:'50%',
        x: "80%",
        opacity: 0,
        ease: Power3.easeInOut
    }, 0);

    return [tl,tl2];


}
