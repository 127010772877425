export function anim9() {

    var tl = gsap.timeline({ repeat: -1, repeatDelay: 2 });
    var tl2 = gsap.timeline({ repeat: -1, repeatDelay: 0 });


    tl2.to(".anim9-lines", {
        duration: 2,
        strokeDashoffset: -100,
        ease: "none"
    });
    tl.to(".anim9-btn", {
        duration: 1,
        scale: 1.1,
        transformOrigin: "30% 50%",
        ease: Power4.easeOut,
        stagger: .2,
    }, 0);
    tl.to(".anim9-btn", {
        duration: 1,
        scale: 1,
        transformOrigin: "30% 50%",
        ease: Power4.easeIn,
        stagger: .2,
    }, 1);
    return [tl, tl2];



}
