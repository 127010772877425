import { anim1 } from './anim1';
import { anim10 } from './anim10';
import { anim11 } from './anim11';
import { anim12 } from './anim12';
import { anim2 } from './anim2';
import { anim3 } from './anim3';
import { anim4 } from './anim4';
import { anim5 } from './anim5';
import { anim6 } from './anim6';
import { anim7 } from './anim7';
import { anim8 } from './anim8';
import { anim9 } from './anim9';
import './scss/main.scss';




export function random(min, max) {
    const delta = max - min;
    return (direction = 1) => (min + delta * Math.random()) * direction;
}

gsap.config({ nullTargetWarn: false });

function run(){
    console.log('ready');
   
    window.Anim = {
        anim1: anim1(),
        anim2: anim2(),
        anim3: anim3(),
        anim4: anim4(),
        anim5: anim5(),
        anim6: anim6(),
        anim7: anim7(),
        anim8: anim8(),
        anim9: anim9(),
        anim10: anim10(),
        anim11: anim11(),
        anim12: anim12(),
    };

}

window.runAnimations = run;

